import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import React, { forwardRef } from 'react';
import InputMask from '@mona-health/react-input-mask';

const TextField: React.FC<TextFieldProps> = forwardRef(({multiline, ...props}, ref) => {
  return (
    <>
      {!multiline ?
        <InputMask size='small' fullWidth {...props}>
          <MuiTextField ref={ref}/>
        </InputMask >
        :
        <MuiTextField ref={ref} multiline fullWidth size='small' {...props}/>
      }
    </> 
  )
});

export default TextField;