import { useState, memo } from 'react';
import { deleteSession, getPermissions } from 'utils/userUtils';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuItem from 'components/MenuItem';
import List from '@mui/material/List';
import Button from 'components/Button';
import {
  HomeRounded,
  TaskRounded,
  InsertDriveFileRounded,
  BarChartRounded,
  SegmentRounded,
  SettingsRounded,
  TextSnippetRounded,
  PrivacyTipRounded,
  HelpRounded,
  LogoutRounded,
  SupportAgentRounded
} from '@mui/icons-material';
import companyAPI from 'apis/CompanyAPI';

function SideMenu(){
  const { t } = useTranslation(['Pages', 'Actions']);
  const [ privacyPolicyNotFoundMsg, setPrivacyPolicyNotFoundMsg ] = useState(false);
  const userAgreementLink = 'https://materiais.praxio.com.br/termos-de-uso-siga-emissor';

  const getPrivacyPolicy = () => {
    companyAPI.getPrivacyPolice()
    .then(resp => {
      resp.blob().then(resp => {
        var fileURL = window.URL.createObjectURL(resp);
        let tab = window.open();
        tab.location.href = fileURL;
      });
    })
    .catch((_error) => {
      setPrivacyPolicyNotFoundMsg(true);
    });
  };
    
  const menu = [
    {
      title: t('Dashboard'),
      icon: <HomeRounded/>,
      route: '/'
    },
    {
      title: t('Cadastros'),
      icon: <TaskRounded/>,
      hidden:
        !getPermissions('customers').view &&
        !getPermissions('vehicles').view &&
        !getPermissions('drivers').view &&
        !getPermissions('transporters').view &&
        !getPermissions('taxrates').view,
      children: [
        { title: t('Clientes'), route: '/clientes', hidden: !getPermissions('customers').view },
        { title: t('Veículos'), route: '/veiculos', hidden: !getPermissions('vehicles').view },
        { title: t('Motoristas'), route: '/motoristas', hidden: !getPermissions('drivers').view },
        { title: t('Transportadores'), route: '/transportadores', hidden: !getPermissions('transporters').view },
        { title: t('Alíquotas'), route: '/aliquotas', hidden: !getPermissions('taxrates').view }
        //{ title: t('Linhas'), route: '/linhas', hidden: !getPermissions('lines').view }
      ]
    },
    // {
    //   title: t('Tabelas'),
    //   icon: <TableRowsRounded/>,
    //   hidden:
    //     !getPermissions('routes').view &&
    //     !getPermissions('padraoVeic').view &&
    //     !getPermissions('accessVehic').view &&
    //     !getPermissions('ranges').view,
    //   children: [
    //     { title: t('Acessórios e Extras'), route: '/acessorios_extras', hidden: !getPermissions('accessVehic').view },
    //     { title: t('Vendedores'), route: '/vendedores', hidden: !getPermissions('sellers').view },
    //     { title: t('Rotas e Trajetos'), route: '/rotas', hidden: !getPermissions('routes').view },
    //     { title: t('Padrões de Veículo'), route: '/padroes_veiculo', hidden: !getPermissions('padraoVeic').view },
    //     { title: t('Faixas'), route: '/faixas', hidden: !getPermissions('ranges').view }
    //   ]
    // },
    {
      title: t('Lançamentos'),
      icon: <InsertDriveFileRounded/>,
      hidden: 
        //!getPermissions('EventualContracts').view &&
        //!getPermissions('budgets').view &&
        !getPermissions('cte-os').view,
      children: [
        //{ title: t('Cotações'), route: '/cotacoes', hidden: !getPermissions('budgets').view },
        //{ title: t('Contrato Eventual'), route: '/contrato_eventual', hidden: !getPermissions('EventualContracts').view },        
        { title: t('CT-e OS'), route: '/cte-os', hidden: !getPermissions('cte-os').view }
      ]
    },
    {
      title: t('Relatórios'),
      icon: <BarChartRounded/>,
      hidden:
        !getPermissions('moviments').view &&
        !getPermissions('dues').view &&
        !getPermissions('export_documents').view,
      children: [
        { title: t('Movimentos do Mês'), route: '/movimentos', hidden: !getPermissions('moviments').view },
        { title: t('Vencimentos'), route: '/vencimentos', hidden: !getPermissions('dues').view },
        { title: t('Exportar Documentos'), route: '/exportar-documentos', hidden: !getPermissions('export_documents').view }
      ]
    },
    {
      title: t('Organizador'),
      icon: <SegmentRounded/>,
      hidden:
        !getPermissions('tasks').view,
      children: [
        { title: t('Tarefas'), route: '/tarefas', hidden: !getPermissions('tasks').view }
      ]
    },
    {
      title: t('Configurações'),
      icon: <SettingsRounded/>,
      hidden:
        !getPermissions('user').view &&
        !getPermissions('user_profiles').view &&
        !getPermissions('companies').view &&
        !getPermissions('establishments').view,
        // !getPermissions('plans').view,
      children: [
        { title: t('Usuários'), route: '/usuarios', hidden: !getPermissions('user').view },
        { title: t('Perfis de Acesso'), route: '/perfis', hidden: !getPermissions('user_profiles').view },
        { title: t('Operações'), route: '/operacoes' },
        { title: t('Empresa'), route: '/empresa', hidden: !getPermissions('companies').view },
        { title: t('Filial'), route: '/filial', hidden: !getPermissions('establishments').view }
        // { title: t('Planos'), route: '/planos', hidden: !getPermissions('plans').view }
      ]
    },
    {
      title: t('Política de Privacidade'),
      icon: <TextSnippetRounded/>,
      onClick: () => getPrivacyPolicy()
    },
    {
      title: t('Termos de Uso'),
      icon: <PrivacyTipRounded/>,
      onClick: () => window.open(userAgreementLink, '_blank')
    },
    {
      title: t('Suporte ao Cliente'),
      icon: <SupportAgentRounded/>,
      route: 'suporte'
    },
    {
      title: t('Ajuda'),
      icon: <HelpRounded/>
    },
    {
      title: t('Sair', { ns: 'Actions'}),
      icon: <LogoutRounded/>,
      onClick: () => deleteSession()
    }
  ];

  return(
    <div>
      <List >
        {menu.map((menu, index) => (
          <MenuItem key={index} {...menu}/>
        ))}
      </List >
      <Dialog
        open={privacyPolicyNotFoundMsg}
        onClose={() => setPrivacyPolicyNotFoundMsg(false)}
      >
        <DialogTitle>
          {'Política de privacidade não encontrada.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A empresa ainda não cadastrou uma política de privacidade.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPrivacyPolicyNotFoundMsg(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
} export default memo(SideMenu);