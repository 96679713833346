import { useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { registerSession, isUserLoggedIn } from 'utils/userUtils';
import { isEmailValid } from 'utils/validationUtils';
import { useForm } from 'react-hook-form';
import { Grid, Link } from '@mui/material';
import Typography from 'components/Typography';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import TextField from 'components/TextField';
import Button from 'components/Button';
import userAPI from 'apis/UserAPI';
import logo from 'assets/images/logo.png';
import logoPraxio from 'assets/images/logo_praxio.png';
import './style.css';

function LoginForm(){
  const location = useLocation();
  const navigate = useNavigate();

  const [ errorMessage, setErrorMessage ] = useState('');
  const from = location.state?.from?.pathname || '/';
  const state = location.state?.from?.state || [];

  const form = useForm();
  const { handleSubmit, control } = form;

  function handleUserLogin(values){

    userAPI.login(values).then(response => {
      registerSession({
        token: response.data.token,
        email: values.EMAIL
      });
      navigate(from, { state: state, replace: true });
      window.location.reload();
    }).catch(error => {
      setErrorMessage(error.message)
    });
  };

  return(
    isUserLoggedIn()
    ?
      <Navigate to={from} state={{ from: location, state: location?.state ?? [] }} replace/>
    :
    <div>
      <img src={logo} alt='Logo' />
      <Form id='login-form' onSubmit={handleSubmit(handleUserLogin)}>
        {location.state?.state.length > 0 &&
          <Typography>Sessão expirada, faça login novamente.</Typography>
        }
        <Grid item container spacing={2}>
          <FormItem label='Usuário' name='EMAIL' control={control}
            rules={{
              required: 'Entre com o seu email.', maxLength: 50,
              validate: (EMAIL) => isEmailValid(EMAIL) || 'Insira um email válido'
            }}
          >
            <TextField/>
          </FormItem>
          <FormItem label='Senha' name='SENHA' control={control}
            rules={{ required: 'Digite uma senha.', minLength: 6, maxLength: 12 }}
          >
            <TextField type='password' />
          </FormItem>
        </Grid>

        <Button fullWidth variant='contained' type='submit'>ENTRAR</Button>
        <Typography sx={{color: 'red'}}>{errorMessage}</Typography>
        <Link href='trocar_senha'>Esqueceu sua senha?</Link>
        
      </Form>
      <img src={logoPraxio} alt='Logo Praxio' />
    </div>
  )
} export default LoginForm;